import React from 'react'
import { Box } from '@nike/eds'

export default function 
({children}) {
  return (
    <div style={{ marginTop: '75px'}}>
            { children}
    </div>
  )
}
