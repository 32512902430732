import { Text } from "@nike/eds";
import styled from "styled-components";

function AvatarMenuHeader({ initials }) {
  return (
    <Wrapper>
      <CustomAvatar>
        <CustomText>{initials}</CustomText>
      </CustomAvatar>
    </Wrapper>
  );
}

export default AvatarMenuHeader;

const Wrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  --custom-avatar-height: 100px;
`;

const CustomText = styled(Text)`
  color: #ffffff;
  font: var(--eds-type-title-2);
  line-height: var(--custom-avatar-height);
`;

const CustomAvatar = styled.div`
  width: var(--custom-avatar-height);
  height: var(--custom-avatar-height);
  border-radius: 50%;
  background-color: #111111;
  text-align: center;
`;
