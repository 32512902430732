import { Switch, Route, useHistory} from "react-router-dom";
import styled from "styled-components";
import HeadBar from "./components/HeaderBar/HeadBar";
import "./App.css";
import VerticalSideBar from "./components/Navigation/VerticalSideBar";
import LandingPage from "pages/LandingPage";
import React, { useState, useEffect} from "react";
  
function App() {

  return (
   
    <div className='app'>
    <HeadBar />
      <VerticalSideBar />
      <main className='container'>
        <Switch>
        <Route path='/' exact={true} component={LandingPage} />
        </Switch>
      </main>
     
    </div>

  );
}

export default App;



const TransformWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: scale(3);
`;
