import { useState, useEffect } from "react";
import {useHistory, useLocation } from 'react-router-dom';
import { Box, VerticalNavbar } from "@nike/eds";
import styles from "./Navigation.css";

const VerticalSideBar =() =>{
    const defaultActiveNavItem = '/';
    const location = useLocation()
    const pathname = location.pathname
    const [activeNavItem, setActiveNavItem] = useState(pathname);
    const history = useHistory();
    const currentTime = new Date().getTime(); // can add more time to make it run quicker, 3579184 == 30secs, 3473184 == 2 mins, 3559184 == 1mins



    const onNavigate = (e) => {
        history.push(e.id);
        setActiveNavItem(e.id);
     };
 
     const showSideNav = (items) => {

        return(
            <Box mt='30px'>
            <VerticalNavbar
          className={styles.VerticalNav}
          navLogoSlot=''
          appName='App Name'
          appSubtitle='Some Subtitle Text'
          onNavigate={onNavigate}
          items={items}
        />
      </Box>)
     }
     const items = [
         {
             id: defaultActiveNavItem,
             active: defaultActiveNavItem === activeNavItem,
             icon: 'Calendar',
             label: 'Home',
         }
        //  {
        //      id: '/product',
        //      active: '/product' === activeNavItem,
        //      icon: 'Shipping',
        //      label: 'Product'
        //  },
        //  {
        //      id: '/access',
        //      active: '/access' === activeNavItem,
        //      icon: 'Settings',
        //      label: 'Access'
        //  },
        //  {
        //     id: '/product1',
        //     active: '/product1' === activeNavItem,
        //     icon: 'Shipping',
        //     label: 'Product'
        // },
        // {
        //     id: '/access1',
        //     active: '/access1' === activeNavItem,
        //     icon: 'Settings',
        //     label: 'Access'
        // },
        // {
        //     id: '/product2',
        //     active: '/product2' === activeNavItem,
        //     icon: 'Shipping',
        //     label: 'Product'
        // },
        // {
        //     id: '/access2',
        //     active: '/access2' === activeNavItem,
        //     icon: 'Settings',
        //     label: 'Access'
        // }
        // ,
        // {
        //     id: '/product3',
        //     active: '/product3' === activeNavItem,
        //     icon: 'Shipping',
        //     label: 'Product'
        // },
        // {
        //     id: '/access3',
        //     active: '/access3' === activeNavItem,
        //     icon: 'Settings',
        //     label: 'Access'
        // },
        // {
        //     id: '/product4',
        //     active: '/product4' === activeNavItem,
        //     icon: 'Shipping',
        //     label: 'Product'
        // }



     ]

return showSideNav(items);
}

export default VerticalSideBar;