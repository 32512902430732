import React, {useState, useEffect} from "react";
import { NikeApp } from "@nike/nike-design-system-icons";
import { AppHeader, Box } from "@nike/eds";
import { Avatar,Icon,IconButton,Text } from "@nike/eds";
import LoginAvatar from "../LoginAvatar";

const ToolbarPlaceHolder = ({ isDark, count }) => (
  <div>
    <IconButton
      icon='Activity'
      label='Activity'
      variant='ghost'
      onClick={() => {}}
      className='eds-color--text-secondary'
    />
    <IconButton
      icon='Burn'
      label='Burn'
      variant='ghost'
      onClick={() => {}}
      className='eds-color--text-secondary'
    />
    <IconButton
      icon='Club'
      label='Club'
      variant='ghost'
      onClick={() => {}}
      className='eds-color--text-secondary'
    />
    <IconButton
      icon='Bag'
      label='Bag'
      variant='ghost'
      onClick={() => {}}
      className='eds-color--text-secondary'
    />
  </div>
);

const DropdownMenuPlaceholder = ({ isDark: boolean }) => (
  <>
    <Text
      style={{
        color: 'var(--eds-color-text-secondary)',
        cursor: 'not-allowed',
      }}
      font={'subtitle-2'}
    >
      Dropdown text{' '}
    </Text>
    <Icon name='CaretDown' color='var(--eds-color-text-secondary)' />
  </>
);

function HeadBar() {

    const [isMobileView, setIsMobileView] = useState(false)
    const [appName, setAppName] = useState(process.env.REACT_APP_TITLE)

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.matchMedia("(max-width: 767px").matches;
            setIsMobileView(isMobile)
        }
        handleResize("NA-EDA")
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    })

    useEffect(() => {
        setAppName(process.env.REACT_APP_TITLE)
    }, [isMobileView])
  return (
    <Box style={{ position: 'fixed', left: 0, width: '100%', zIndex: 1}}>
<AppHeader
  isDark
  appName={appName}
  logoSlot={<NikeApp color={'white'} width={'56px'} height={'64px'} />}
  // toolbarSlot={<ToolbarPlaceHolder isDark />}
  avatarSlot={<LoginAvatar isNavbar={true}/>}
/>
</Box>
  )
};

export default HeadBar;