import React, { useState } from "react";
import { IconButton, Menu, Spinner } from "@nike/eds";
import { Button } from "@nike/eds";

import AvatarMenuBody from "./AvatarMenuBody";
import AvatarMenuHeader from "./AvatarMenuHeader";

function LoginAvatar({isNavbar}) {
  const [isOpen, setIsOpen] = useState(false);

  if (isNavbar) {

    return (
      <Menu
        headerSlot={<AvatarMenuHeader />}
        bodySlot={<AvatarMenuBody />}
        isOpen={isOpen}
        onClickOutside={() => setIsOpen(!isOpen)}
        placement={"bottom-end"}
      >
        <IconButton
          onClick={() => setIsOpen(!isOpen)}
          icon='Profile'
          label='example button'
        />
      </Menu>
    );
  }
}

export default LoginAvatar;
