import { Text } from "@nike/eds";
import styled from "styled-components";

function AvatarMenuBody({ name, email }) {
  return (
    <Wrapper>
      <Text font={"body-3"} color={"text-secondary"} as={"p"}>
        {name}
      </Text>
      <Text
        style={{ textTransform: "lowercase" }}
        font={"legal-1"}
        color={"text-secondary"}
        as={"p"}
      >
        {email}
      </Text>
    </Wrapper>
  );
}

export default AvatarMenuBody;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
