import React, { useState, useEffect } from 'react'
import { TextStyled } from "@nike/nike-design-system-components";
import '../../src/static/css/landingpage.css'
import ContainerBox from 'utils/ContainerBox';
import { Box } from "@mui/material";
const LandingPage = () => {
  const [appearance1, setAppearance1] = useState("display2")
  const [appearance2, setAppearance2] = useState("title2")
  const [isMobileView, setIsMobileView] = useState(false)
  
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 767px").matches;
      setIsMobileView(isMobile)
    }
    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
   
  }, [])


  useEffect(() => {
    setAppearance1(isMobileView ? "display3" : "display2")
    setAppearance2(isMobileView ? "title3" : "title2")
  }, [isMobileView])

  useEffect(() => {
    const tempDiv = document.querySelector('.hero-inner-container');
  }, [])

  return (
    <>
          <div className='hero-container'>
              <div className='hero-backdrop'>
                <div style={{display: 'flex'}}>
                  <div className='placeholder-for-mobile'></div>
                  <div className='hero-text-wrapper'>
                    <ContainerBox >
                      <Box sx={{ width: '100%' }}>
                        <TextStyled appearance={appearance1} style={{ padding: '20px' }}>Welcome To</TextStyled>
                        <Box sx={{ padding: '20px' }}>
                          <TextStyled appearance={appearance2} >iTMS Management Console</TextStyled>
                        </Box>
                      </Box>
                    </ContainerBox>
                  </div>
                </div>
              </div>
            </div>
        
    </>
  )
}


export default LandingPage